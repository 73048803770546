<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        Consider the structure and curved arrow depicted below. What resonance structure would
        result from the movement of electrons shown here.
      </p>

      <p class="mb-5 pl-12">
        <v-img :src="image1Name" style="max-width: 320px" />
      </p>

      <v-radio-group v-model="inputs.studentAnswer" :disabled="!allowEditing" class="mb-0">
        <v-radio v-for="option in options" :key="option.value" :value="option.value" class="my-3">
          <template #label>
            <v-img :src="option.img" style="max-width: 97px" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI51LCPL3Q3',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
      items: [
        {img: '/img/assignments/ChemUCI51LCPL3Q3_optionA.png', value: 'a'},
        {img: '/img/assignments/ChemUCI51LCPL3Q3_optionB.png', value: 'b'},
        {img: '/img/assignments/ChemUCI51LCPL3Q3_optionC.png', value: 'c'},
        {img: '/img/assignments/ChemUCI51LCPL3Q3_optionD.png', value: 'd'},
      ],
    };
  },
  computed: {
    image1Name() {
      return '/img/assignments/ChemUCI51LCPL3Q3_main.png';
    },
    options() {
      return seededShuffle(this.items, this.seed);
    },
    seed() {
      return this.$gate.user?.id || 1;
    },
  },
};
</script>
<style scoped></style>
